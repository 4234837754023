import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class ValidationMetadata extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      'Error-Rows': 'errorCount',
      'Error-Rows-List': 'errorRowsList',
      'Total-Rows': 'totalCount',
      'Warning-Rows': 'warningCount',
      'Potential-Match-Percentage': 'potentialMatchPercent'
    }
  }

  private errorCount = 0
  private errorRowsList = ''
  private totalCount = 0
  private warningCount = 0
  private potentialMatchPercent = 0.00

  getErrorCount (): number {
    return this.errorCount
  }

  setErrorCount (value: number | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.errorCount = value
  }

  getErrorRowsList (): string {
    return this.errorRowsList
  }

  setErrorRowsList (value: string) {
    this.errorRowsList = value
  }

  getTotalCount (): number {
    return this.totalCount
  }

  setTotalCount (value: number | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.totalCount = value
  }

  getWarningCount (): number {
    return this.warningCount
  }

  setWarningCount (value: number | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.warningCount = value
  }

  getPotentialMatchPercent (): number {
    return this.potentialMatchPercent
  }

  setPotentialMatchPercent (value: number | string) {
    if (typeof value === 'string') {
      value = parseFloat(value)
    }

    this.potentialMatchPercent = value
  }

  fromApiTransformer (data: JsonData, api: Api): ValidationMetadata {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
