import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class ValidationError extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.IM]: {
      errorCode: 'errorCode',
      errorDescription: 'errorDescription'
    }
  }

  private errorCode = 0
  private errorDescription = ''

  getErrorCode (): number {
    return this.errorCode
  }

  setErrorCode (value: number) {
    this.errorCode = value
  }

  getErrorDescription (): string {
    return this.errorDescription
  }

  setErrorDescription (value: string) {
    this.errorDescription = value
  }

  fromApiTransformer (data: JsonData, api: Api): ValidationError {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
