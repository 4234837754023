var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 mt-4", attrs: { "data-test": "mapping-container" } },
    [
      _c(
        "b-container",
        { staticClass: "w-100" },
        [
          _vm.isLoading
            ? _c(
                "b-row",
                { staticClass: "w-100 justify-content-md-center" },
                [
                  _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "loading",
                            expression: "'loading'"
                          }
                        ],
                        staticClass:
                          "bg-light w-100 p-5 mt-5 rounded-lg d-flex flex-column align-items-center"
                      },
                      [
                        _c("b-spinner"),
                        _c("h4", { staticClass: "mt-3" }, [
                          _vm._v("Uploading your file...")
                        ]),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("This may take a while")
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : [
                _c(
                  "div",
                  {
                    staticClass: "w-100 bg-white p-4 rounded-lg bordered",
                    attrs: { "data-test-match-data": "mapping-container" }
                  },
                  [
                    _c(
                      "h5",
                      [_c("b-icon-files-alt"), _vm._v(" Match Data")],
                      1
                    ),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " Tell us which headings in your CSV to use for matching your data. The more you can provide, the better. "
                      )
                    ]),
                    _c("p", { staticClass: "text-whale" }, [
                      _vm._v("Please provide a Date & Time.")
                    ]),
                    _c(
                      "b-row",
                      { staticClass: "mb-1 text-whale mappingHeaders" },
                      [
                        _c("b-col", [_vm._v(" Smart Match Field ")]),
                        _c("b-col", [_vm._v(" CSV Header ")]),
                        _c("b-col", [_vm._v(" Preview ")]),
                        _c("b-col", [_vm._v(" Format ")]),
                        _c("b-col", [_vm._v(" Timezone ")]),
                        _c("b-col", { attrs: { cols: "1" } })
                      ],
                      1
                    ),
                    _vm._l(_vm.mappedMatchFields, function(mapping, index) {
                      return _c("FieldMap", {
                        key: index,
                        attrs: {
                          mapping: mapping,
                          "available-fields": _vm.matchFields,
                          "available-headers": _vm.availableHeaders,
                          "format-config": _vm.formatConfig
                        },
                        on: { delete: _vm.deleteMapping }
                      })
                    }),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "addMatchField",
                            expression: "'addMatchField'"
                          }
                        ],
                        attrs: {
                          disabled:
                            !_vm.availableHeaders.length ||
                            !_vm.matchFields.length
                        },
                        on: { click: _vm.addMatchField }
                      },
                      [_c("b-icon-plus"), _vm._v(" Add field")],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "w-100 bg-white p-4 rounded-lg bordered mt-4"
                  },
                  [
                    _c(
                      "h5",
                      [_c("b-icon-cash-stack"), _vm._v(" Conversion Data")],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        " Map the headings in your CSV that contain information about your sales. "
                      )
                    ]),
                    _c(
                      "b-row",
                      { staticClass: "mb-1 text-whale mappingHeaders" },
                      [
                        _c("b-col", [_vm._v(" Smart Match Field ")]),
                        _c("b-col", [_vm._v(" CSV Header ")]),
                        _c("b-col", [_vm._v(" Preview ")]),
                        _c("b-col", [_vm._v(" Format ")]),
                        _c("b-col", [_vm._v(" Timezone ")]),
                        _c("b-col", { attrs: { cols: "1" } })
                      ],
                      1
                    ),
                    _vm._l(_vm.mappedConversionFields, function(
                      mapping,
                      index
                    ) {
                      return _c("FieldMap", {
                        key: index,
                        attrs: {
                          mapping: mapping,
                          "available-fields": _vm.conversionFields,
                          "available-headers": _vm.availableHeaders,
                          "format-config": _vm.formatConfig
                        },
                        on: { delete: _vm.deleteMapping }
                      })
                    }),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "add conversion field",
                            expression: "'add conversion field'"
                          }
                        ],
                        attrs: {
                          disabled:
                            !_vm.availableHeaders.length ||
                            !_vm.conversionFields.length
                        },
                        on: { click: _vm.addConversionField }
                      },
                      [_c("b-icon-plus"), _vm._v(" Add field")],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "mt-3 d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "continue button",
                            expression: "'continue button'"
                          }
                        ],
                        staticClass: "ml-auto",
                        attrs: { disabled: _vm.isLoading, variant: "success" },
                        on: { click: _vm.doContinue }
                      },
                      [
                        _vm.isLoading
                          ? _c("b-spinner", { attrs: { small: "" } })
                          : _vm._e(),
                        _vm._v(" Continue")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }