














































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IntelligentMatchField } from '@/store'
import FieldMap from '@/components/FieldMap.vue'
import { BIconCashStack, BIconFilesAlt, BIconPlus } from 'bootstrap-vue'
import { filter, find, get } from 'lodash'
import { JsonData } from 'mfe-shared/app/src/helpers/api'

export enum FieldType { Match, Conversion }
export type FieldConfig = { field: IntelligentMatchField; label: string; type: FieldType; required?: boolean; allowMultiple?: boolean; datetimeField?: boolean; placeholder?: boolean; phoneNumberField?: boolean; selected?: boolean }
export type FieldMapping = { field: FieldConfig; header: string; datetimeFormat?: string; phoneNumberCountryCode?: string }
export type FormatConfig = { datetimeFormat: string | null; callStartDatetimeFormat: string | null; timezone: string | null; phoneNumberCountryCode: string | null }

export const FieldConfigs: FieldConfig[] = [
  { field: IntelligentMatchField.EventDatetime, label: 'Date & Time', type: FieldType.Match, required: true, datetimeField: true },
  { field: IntelligentMatchField.SrcPhoneNumber, label: 'Caller ID', type: FieldType.Match, allowMultiple: true, phoneNumberField: true },
  { field: IntelligentMatchField.CallStartDatetime, label: 'Call Start Date & Time', type: FieldType.Match, datetimeField: true },
  { field: IntelligentMatchField.MatchMode, label: 'Match Mode', type: FieldType.Match },
  { field: IntelligentMatchField.CallRef, label: 'Call Ref', type: FieldType.Match },
  { field: IntelligentMatchField.VisitorId, label: 'Visitor ID', type: FieldType.Match },
  { field: IntelligentMatchField.Placeholder, label: 'Please select a field', type: FieldType.Conversion, placeholder: true },
  { field: IntelligentMatchField.Action, label: 'Action', type: FieldType.Conversion },
  { field: IntelligentMatchField.Title, label: 'Title', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionValue, label: 'Transaction Value', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionCurrency, label: 'Transaction Currency', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionRef, label: 'Transaction Reference', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionListPrice, label: 'List Price', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionProduct, label: 'Product', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionProductCategory, label: 'Product Category', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionProfit, label: 'Profit', type: FieldType.Conversion },
  { field: IntelligentMatchField.TransactionCustomerRef, label: 'Customer Reference', type: FieldType.Conversion },
  { field: IntelligentMatchField.Value, label: 'Value', type: FieldType.Conversion },
  { field: IntelligentMatchField.AgentRef, label: 'Agent Ref', type: FieldType.Conversion }
]

export const DatetimeFormats: string[] = [
  'yyyy-MM-dd',
  'yyyy-MM-dd hh:mm:ss',
  'yyyy-MM-dd hh:mm:ss.SSS',
  'yyyy-MM-ddThh:mm:ssZ',
  'yyyy-MM-ddThh:mm:ss.SSSZ',
  'yyyy-dd-MM',
  'yyyy-dd-MM hh:mm:ss',
  'yyyy-dd-MM hh:mm:ss.SSS',
  'dd-MM-yyyy',
  'dd-MM-yyyy hh:mm',
  'dd-MM-yyyy hh:mm:ss',
  'dd/MM/yyyy',
  'dd/MM/yyyy hh:mm',
  'dd/MM/yyyy hh:mm:ss',
  'MM/dd/yyyy',
  'MM/dd/yyyy hh:mm',
  'MM/dd/yyyy hh:mm:ss'
]

export const Timezones: string[] = [
  'UTC',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Helsinki',
  'Europe/Istanbul',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'America/Adak',
  'America/Anchorage',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Edmonton',
  'America/Halifax',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Los_Angeles',
  'America/New_York',
  'America/Phoenix',
  'America/Regina',
  'America/St_Johns',
  'America/Toronto',
  'America/Vancouver',
  'America/Winnipeg',
  'Pacific/Honolulu',
  'Pacific/Pago_Pago'
]

@Component({
  components: {
    FieldMap,
    BIconFilesAlt,
    BIconCashStack,
    BIconPlus
  }
})
export default class Mapping extends Vue {
  mappedFields: FieldMapping[] = []
  isLoading = false
  hasSavedHeaders = false

  formatConfig: FormatConfig = {
    datetimeFormat: null,
    callStartDatetimeFormat: null,
    timezone: null,
    phoneNumberCountryCode: null
  }

  async created () {
    if (!this.$store.state.uploadedFile || !this.$store.state.csvFile) {
      this.$router.push('/')
    }

    await this.$store.dispatch('getSavedMapping')

    let savedHeaders = this.$store.state.mappingConfig

    if (typeof savedHeaders === 'object' && savedHeaders !== null) {
      savedHeaders = Object.keys(this.$store.state.mappingConfig)
    }

    if (savedHeaders && savedHeaders.length > 0) {
      this.addSavedMappingFields()
      this.updateDatetimeFormatConfig()

      const map = this.generateMap()

      this.$bvToast.toast('We have pre-populated your Data Mapping using information from your last Smart Match upload.', {
        title: 'Found previous Smart Match Data Mapping',
        variant: 'info',
        autoHideDelay: 5000
      })

      this.$store.commit('setMappingConfig', map)
      this.formatConfig = { ...this.$store.state.formatConfig }
      this.addPlaceholderConversionField()

      await this.$mixpanelService.track('settings.smartMatch.dataMapped', {
        mappingConfig: JSON.stringify(map),
        datetimeFormat: this.formatConfig.datetimeFormat ?? '',
        callStartDatetimeFormat: this.formatConfig.callStartDatetimeFormat ?? '',
        timezone: this.formatConfig.timezone ?? '',
        phoneNumberCountryCode: this.formatConfig.phoneNumberCountryCode ?? ''
      })

      return
    }

    if (!this.$store.state.savedMappingFound) {
      this.$bvToast.toast('There is no previously saved mapping. Please map your fields below.', {
        title: 'No previous Smart Match found',
        variant: 'info',
        autoHideDelay: 5000
      })
    }

    for (const requiredConfig of filter(FieldConfigs, 'required')) {
      this.mappedFields.push({ field: requiredConfig, header: '' })
    }

    this.addPlaceholderConversionField()

    this.formatConfig.timezone = this.$infinityAuth.getInstallation().getTimeZone() ?? 'UTC'
    this.formatConfig.phoneNumberCountryCode = this.$infinityAuth.getProfile().getCountryCode()
  }

  getAvailableFieldsByType (type: FieldType) {
    return FieldConfigs.filter((config) => {
      if (config.type === type) {
        if (config.allowMultiple) {
          return true
        }

        if (config.required) {
          return false
        }

        if (config.placeholder) {
          return false
        }

        const mappedField = find(this.mappedFields, { field: config })

        return !mappedField
      }
    })
  }

  get availableHeaders () {
    return this.$store.state.csvFile?.getHeaders()?.filter(
      (header: string) => {
        const mappedField = find(this.mappedFields, { header })

        return !mappedField
      }
    ) || []
  }

  get matchFields () {
    return this.getAvailableFieldsByType(FieldType.Match)
  }

  get conversionFields () {
    return this.getAvailableFieldsByType(FieldType.Conversion)
  }

  get mappedMatchFields () {
    return this.mappedFields.filter(
      (mappedField) => {
        return mappedField.field.type === FieldType.Match
      }
    )
  }

  get mappedConversionFields () {
    return this.mappedFields.filter(
      (mappedField) => {
        return mappedField.field.type === FieldType.Conversion
      }
    )
  }

  addMatchField () {
    if (this.matchFields[0]) {
      this.mappedFields.push({ field: this.matchFields[0], header: '' })
    }
  }

  addConversionField () {
    if (this.conversionFields[0]) {
      this.mappedFields.push({ field: this.conversionFields[0], header: '' })
    }
  }

  addPlaceholderConversionField () {
    if (this.mappedConversionFields.length === 0) {
      const placeholderField = FieldConfigs.find((field) => { return field.placeholder })
      if (!placeholderField) {
        return
      }

      this.mappedFields.push({ field: placeholderField, header: '' })
    }
  }

  addSavedMappingFields () {
    if (!this.hasSavedHeaders) {
      const mappingConfig: { [key: string]: string[] } = {}
      // swap the key and values for the stored saved mappings
      Object.keys(this.$store.state.mappingConfig).forEach(key => {
        const mappingValue = this.$store.state.mappingConfig[key].replace(/\d+$/, '')
        if (typeof mappingConfig[mappingValue] === 'undefined') {
          mappingConfig[mappingValue] = []
        }
        mappingConfig[mappingValue].push(key)
      })

      FieldConfigs.forEach((field) => {
        if (typeof mappingConfig[field.field] !== 'undefined') {
          // push the stored saved mappings in the the mapped fields
          // so they can display on screen
          mappingConfig[field.field].forEach(mapping => {
            this.mappedFields.push({ field: field, header: mapping })
          })
        }
      })
      // only needs to be done when loading a saved mapping
      this.hasSavedHeaders = true
    }
  }

  deleteMapping (mapping: FieldMapping) {
    let index = -1
    this.mappedFields.some((mappedField, i) => {
      if (mappedField.header === mapping.header) {
        index = i
        return true
      }
    })

    if (index > -1) {
      this.mappedFields.splice(index, 1)
    }

    if (this.mappedConversionFields.length === 0) {
      this.addPlaceholderConversionField()
    }

    const map = this.generateMap()
    this.$store.dispatch('updateMappingConfig', map)
  }

  generateMap () {
    const map: { [k: string]: string } = {}
    let counter = 0

    const filteredFields = this.mappedFields.filter((field) => { return !field.field.placeholder })

    filteredFields.forEach((mapping) => {
      let field = `${mapping.field.field}`

      if (mapping.field.allowMultiple) {
        counter++
        field = `${field.replace(/\d+$/, '')}${counter}`
      }

      Vue.set(map, mapping.header, field)
    })

    return map
  }

  updateDatetimeFormatConfig () {
    const eventDatetimeField = this.mappedFields.find((field) => field.field.field === IntelligentMatchField.EventDatetime)
    if (eventDatetimeField?.datetimeFormat) {
      this.formatConfig.datetimeFormat = eventDatetimeField.datetimeFormat
    }

    const callStartDatetimeField = this.mappedFields.find((field) => field.field.field === IntelligentMatchField.CallStartDatetime)
    if (callStartDatetimeField?.datetimeFormat) {
      this.formatConfig.callStartDatetimeFormat = callStartDatetimeField.datetimeFormat
    }
  }

  @Watch('$store.state.mappingConfig', {
    deep: true
  })
  @Watch('$store.state.formatConfig', {
    deep: true
  })
  async setMappingShouldBeSaved (newValue: JsonData | FormatConfig, oldValue: JsonData | FormatConfig) {
    if (newValue !== oldValue) {
      this.$store.dispatch('mappingHasUpdates', true)
    }
  }

  async doContinue () {
    this.isLoading = true
    this.$store.commit('setNetworkError', false)

    if (this.$store.state.hasMappingChanges) {
      this.addSavedMappingFields()
    }

    this.updateDatetimeFormatConfig()

    try {
      const map = this.generateMap()

      this.$store.commit('setMappingConfig', map)
      this.$store.commit('setFormatConfig', this.formatConfig)

      await this.$store.dispatch('getMappedCsv')
      await this.$store.dispatch('getUploadUrl')
      await this.$store.dispatch('uploadFile')

      await this.$mixpanelService.track('settings.smartMatch.dataMapped', {
        mappingConfig: JSON.stringify(map),
        datetimeFormat: this.formatConfig.datetimeFormat ?? '',
        callStartDatetimeFormat: this.formatConfig.callStartDatetimeFormat ?? '',
        timezone: this.formatConfig.timezone ?? ''
      })

      await this.$router.push(`/${this.$store.state.filename}`)
    } catch (e) {
      const message = get(e, 'response.data.error', 'An unknown error occurred, please try again')

      if (message === 'An unknown error occurred, please try again') {
        this.$store.commit('setNetworkError', true)
        this.isLoading = false
        this.$router.push('/network-error')
      }

      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000
      })
    }

    this.isLoading = false
  }
}
