












































































































































import { Component, Emit, Vue } from 'vue-property-decorator'
import {
  BIconPercent,
  BIconExclamationDiamondFill,
  BIconExclamationTriangleFill,
  BIconCloudDownload,
  BIconArrowClockwise,
  BIconCloudUpload,
  BIconInfoCircle,
  BIconCheckCircleFill
} from 'bootstrap-vue'
import { get } from 'lodash'

// 5 seconds
const pollInterval = 5000

// 5 minutes
const maxPollingTime = 300000

@Component({
  components: {
    BIconPercent,
    BIconExclamationDiamondFill,
    BIconExclamationTriangleFill,
    BIconCloudDownload,
    BIconArrowClockwise,
    BIconCloudUpload,
    BIconInfoCircle,
    BIconCheckCircleFill
  }
})
export default class ResultsView extends Vue {
  poller: number | null = null
  isLoading = true
  isQueuing = false
  pollingTime = 0

  get isPollingTimedOut () {
    return this.pollingTime >= maxPollingTime
  }

  async mounted () {
    const filename = get(this.$route, 'params.filename')

    if (!filename) {
      await this.$store.dispatch('reset')
      await this.$router.push('/')
    }

    this.$store.commit('setFilename', filename)

    if (this.$store.state.networkError) {
      this.isLoading = false
    }

    try {
      await this.$store.dispatch('getValidationResults')
    } catch (e) {
      const status = get(e, 'response.status', 0)

      if (status === 404) {
        this.poller = window.setInterval(
          async () => {
            if (this.pollingTime < maxPollingTime) {
              try {
                await this.$store.dispatch('getValidationResults')
              } catch (err) {
                this.pollingTime += pollInterval

                if (get(err, 'response.status', 0) === 404) {
                  return
                }
              }
            }

            this.isLoading = false
            if (this.poller) {
              window.clearInterval(this.poller)
            }
          }, pollInterval
        )

        return
      }
    }

    this.isLoading = false
  }

  get error () {
    if (this.$store.state.validationResults === null) {
      return null
    }

    return this.$store.state.validationResults.getError()
  }

  get resultsMeta () {
    if (this.$store.state.validationResults === null) {
      return null
    }

    return this.$store.state.validationResults.getMetadata()
  }

  beforeDestroy () {
    this.appDeactivated()
  }

  async doQueue () {
    this.isQueuing = true

    try {
      await this.$store.dispatch('storeMapping')
      await this.$store.dispatch('submitBatch')
      await this.$store.dispatch('reset')
      if (!this.$store.state.uploadRoutesOnly) {
        await this.$router.push('/upload-history')
      }

      this.$bvToast.toast('Your uploaded CSV is now processing. Any matches will appear in the Transaction Log.', {
        title: 'CSV Uploaded',
        variant: 'success',
        autoHideDelay: 5000
      })
    } catch (e) {
      this.$bvToast.toast('We encountered a problem queuing this file for processing. Please try again.', {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000
      })
    }

    await this.$mixpanelService.track('settings.smartMatch.queue', {
      filename: get(this.$route, 'params.filename'),
      totalRows: this.resultsMeta?.getTotalCount(),
      potentialMatchPercentage: this.resultsMeta?.getPotentialMatchPercent()
    })

    if (this.$store.state.uploadRoutesOnly) {
      // give time for toast to be read before going to an external route
      setTimeout(async () => {
        this.goToExternalHistoryRoute()

        // reset router path
        await this.$router.push('/')
      }, 4000)
    }

    this.isQueuing = false
  }

  @Emit('go-to-external-route')
  goToExternalHistoryRoute () {
    return 'history'
  }

  appDeactivated () {
    if (this.poller) {
      window.clearInterval(this.poller)
    }
  }

  async doReset () {
    await this.$store.dispatch('reset')
    await this.$router.push('/')
  }
}
